// IMPORTS
const _ = require('lodash');
const axios = require('axios');

// DEFINITIONS
const baseAPI = 'ui/loadup';

interface State {
    // banners: any[];
    // contenidos: any[];
    // ligas: any[];
    msisdn: any[];
}
const state: State = {
    // banners: [],
    // contenidos: [],
    // ligas: [],
    msisdn: [],
};

const getters = {
    // uiGetLigasCfg: (state: State) => {
    //     return state.ligas;
    // },
    // uiGetContenidosCfg: (state: State) => {
    //     return state.contenidos;
    // },
    // uiGetBannersCfg: (state: State) => {
    //     return state.banners;
    // },
    uiGetMsisdnCfg: (state: State) => {
        return state.msisdn;
    },
};

const mutations = {
    // uiSetLigasCfg: (state: State, payload: any) => {
    //     state.ligas = _.map(payload, (liga: any) => {
    //         return liga
    //     })
    // },
    // uiSetContenidosCfg: (state: State, payload: any) => {
    //     state.contenidos = _.map(payload, (hero: any) => {
    //         return hero
    //     })
    // },
    // uiSetBannersCfg: (state: State, payload: any) => {
    //     state.banners = _.map(payload, (hero: any) => {
    //         return hero
    //     })
    // },
    uiSetMsisdn: (state: State, payload: any) => {
        state.msisdn = payload;
    }
};

const actions = {
    uiLoadUp: async ({ commit }: any, args: any) => {
        try {
            const msisdn = (args.msisdn) ? args.msisdn : null,
                iccid = (args.iccid) ? args.iccid : null,
                url = (baseAPI + "/" + msisdn + '/' + iccid),
                response = await axios.get(url);
            // commit('uiSetBannersCfg', response.data.banners)
            commit('uiSetMsisdn', response.data.msisdn)
        } catch (e) {
            console.error(e);
        }
    },
};

// EXPORTS
export default {
    state,
    getters,
    mutations,
    actions,
};