<template>
	<b-navbar id="main-nav" toggleable="lg">
		<b-navbar-brand href="#/">
			<img id="blackfon-portalcautivo-logo" src="@/assets/Exports/Blackfon-White over black - nonsquare.png" alt="Blackfon" />
		</b-navbar-brand>

		<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item href="/">
					<i class="material-symbols-outlined">home</i>
					<!-- {{ "Inicio" | i }} -->
				</b-nav-item>
				<!-- 
				<b-nav-item href="#/menu">
					{{ "our_menu" | i }}
				</b-nav-item>
				<b-nav-item href="#/servicio-chefs">
					{{ "servicio_de_chefs_title" | i }}
				</b-nav-item>
				<b-nav-item href="#/servicio-por-dias">
					{{ "chefs_dias_servicio_title" | i }}
				</b-nav-item>
				<b-nav-item href="#/servicio-bajo-demanda">
					{{ "chefs_ondemand_title" | i }}
				</b-nav-item> -->
				<!-- <links-viewer grupo="Cabecera" navMode /> -->
				<!-- <lang-switcher @reload="$emit('reload')" /> -->
				<!-- <b-nav-item href="#/acerca-de">Sobre nosotros</b-nav-item> -->
				<!-- <b-nav-item href="#/aviso-de-privacidad">Aviso de privacidad</b-nav-item> -->
				<!-- <b-nav-item href="#/contacto">Contacto</b-nav-item> -->
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
import Vue from "vue";
import langSwitcher from './lang-switcher.vue'
export default Vue.extend({
	name: "BlackfonMxNav",
	components: { langSwitcher },

});
</script>

<style lang="less">
#main-nav {
	text-transform: uppercase;
	position: relative;
	z-index: 100;
	border-bottom: 2px solid white;
	a {
		color: white;
	}

	.material-symbols-outlined {
		margin-top: -1em;
		margin-bottom: -1em;
		position: relative;
		top: 0.25em;
	}

	#blackfon-portalcautivo-logo {
		height: 2.5em;
	}

	&.navbar-light {
		.navbar-toggler {
			border: none;
		}
	}
}
</style>