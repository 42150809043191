interface MenuHeader {
    precio_por_persona: 0
}

interface State {
    personsNum: any,
    menuHeader: MenuHeader,
    configs: [],
    pedidoDetail: any,
};

// IMPORTS
const _     = require('lodash'),
    axios   = require('axios'),
    baseAPI = 'menupurchase',
    state: State = {
        personsNum: null,
        menuHeader: {
            precio_por_persona: 0
        },
        configs: [],
        pedidoDetail: {
            folio: null,
            created: null,
            modified: null,
            agenda_inicio: null,
            pedidoTipo: null,
            personas: null,
            origenInsumos: null,
            total: null,
            estatus: null,
            comentarios_cliente: null,
            cliente: {
                nombres: null,
                email: null,
                telefono_celular: null,
            },
            domicilio: {
                titulo: null,
                calle: null,
                numero_interior: null,
                numero_exterior: null,
                codigo_postal: null,
                telefono_fijo: null,
                comentarios: null,
                modified: null,
                colonia: null,
                municipio: null,
                estado: null,			
            },
            pedidoCfgs: []
        }
    };

const getters = {
    getPedido: (state: State) => {
        return state.pedidoDetail
    },
    getMenuIsReadyToPay: (state: State) => {
        const isReady = false;
        console.log('getMenuIsReadyToPay', state)
        return isReady
    },
    getMenuPurchaseTotal: (state: State) => {
        let tot = state.personsNum * state.menuHeader.precio_por_persona;
        _.forEach(state.configs, (item: any) => {
            console.log('getMenuPurchaseTotal', item)
            tot += item.costo_extra
        })
        return tot;
    },
    getMenuPurchasePersonsNum: (state: State) => {
        return state.personsNum;
    },
    getMenuPurchaseConfigs: (state: State) => {
        return state.configs;
    },
    getMenuPurchasedHeader: (state: State) => {
        return state.menuHeader;
    }
};

const mutations = {
    setPedido: (state: State, payload: any) => {
        let pedidoDetail = _.merge(payload.originalData, payload.asides);
        state.pedidoDetail = _.merge(state.pedidoDetail, pedidoDetail)
    },
    setMenuPurchasedHeaders: (state: State, payload: any) => {
        state.menuHeader = payload.menuHeader;
        state.personsNum = payload.personsNum;
    },
    setMenuPurchaseCfg: (state: State, payload: any) => {
        try {
            if (_.isNil(state.menuHeader.precio_por_persona) || state.menuHeader.precio_por_persona == 0) {
                throw 'Error de configuración de cabecera';
            } else {
                // _.forEach(state.menuHeader, (value: any, itemKeyName: string) => {
                    // _.forEach(['appetizers', 'entree', 'main_dishes', 'preparations', 'side_dishes'], (itemKeyName2: string) => {
                    //     if (itemKeyName == itemKeyName2) {
                    //         let count: number = 0,
                    //             maxLimit: number = parseInt(value) * parseInt(state.personsNum);

                    //         _.forEach(_.filter(payload, { descriptor_type: itemKeyName2 }), (item3: any) => {
                    //             count += parseInt(item3.cantidad);
                    //         })

                    //         if (maxLimit != count) throw 'Error de conteo en ' + itemKeyName;
                    //     }
                    // })
                // })
            }
            state.configs = payload;
        } catch (e) {
            console.log(e)
            state.configs = []
        }
    },
};

const actions = {
    applyMenuPurchaseCfg: async ({ commit }: any, payload: State) => {
        try {
            commit('setMenuPurchaseCfg', payload.configs)
        } catch (e) {
            console.error(e)
        }
    }
};

// EXPORTS
export default {
    state,
    getters,
    mutations,
    actions,
};
