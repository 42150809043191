<template>
	<div id="app" v-if="isDisplaying">

		<main-nav @reload="reload" />

		<router-view v-if="!isLoading" class="mainContent" />

		<div key="mainLanding" class="app-loading animate__animated animate__fadeIn" v-else>
			<ui-spinner />
		</div>
		<!-- <contact-form class="hide-on-print fix-bottom" /> -->

		<!-- <main-footer /> -->

	</div>
</template>

<script lang="ts">

import 'animate.css';
import Vue from "vue";
import ContactForm from "./components/contact-form.vue";
import MainFooter from "./components/MainFooter.vue";
import MainNav from "./components/MainNav.vue";

import { mapActions } from "vuex";

/**
 * Redirige un dominio a SSL por JS, permite definir el DNS seguro.
 *
 * Daniel Lepe 2020
 * yo@daniellepe.com
 */
function forceSSL() {
	// REVIEW OF FORCED SSL DNS, THIS CASE RELOCATES ONLY IN [foots.com.mx]
	let regexDOMAN = /\/\/culinarychefs\.mx/,
		regEX = /^http\:/;
	if (regexDOMAN.test(window.document.documentURI) && regEX.test(window.document.documentURI)) {
		window.document.location = window.document.documentURI.replace(regEX, 'https:')
	}
}

export default Vue.extend({
	name: "app",
	components: { MainNav, MainFooter, ContactForm },
	data: () => {
		return {
			isLoading: false,
			isDisplaying: true
		}
	},
	methods: {
		// ...mapActions(['uiLoadUp']),
		async reload() {
			this.isDisplaying = false;
			await this.$nextTick()
			this.isDisplaying = true;
		},
		loadScript(scriptURI: string) {
			// This is necessary to force Vue to insert the cordova.js plugin.
			var script = document.createElement("script");
			script.setAttribute("src", scriptURI);
			script.setAttribute("type", "text/javascript");
			document.getElementsByTagName("head")[0].appendChild(script);
			// console.log("loadScript", scriptURI)
		}
	},
	async beforeMount() {
		forceSSL();
		this.isLoading = true;
	},
	async mounted(){
		const timeout = 1000;
		setTimeout(async () => {
			// await this.uiLoadUp(this.$route.query)
			this.loadScript("https://js.stripe.com/v3/")
			this.isLoading = false;
		}, timeout);
	}
});
</script>

<style scoped>
	.mainContent {
		min-height: calc(80vh - 10em);
	}

	.fix-bottom {
		margin-bottom: 0;
	}
</style>