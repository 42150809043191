// AXIOS SETUP
const axios = require('axios');
const utils = require('./utils.js')

import { store } from "./store/index";

const liveRefresh: boolean = false;

const serverURI = (liveRefresh) ?  'https://848d4b4db272.ngrok.app/server.php?request=' : './server.php?request=';

axios.defaults.baseURL = serverURI;
axios.defaults.timeout = 60000;

axios.interceptors.request.use((config: any) => {

	// Do something before request is sent
	config.headers['Content-Type'] = 'application/json';

	if (!!store.getters.accountGetToken)
		config.headers['AUTH-TOKEN'] = store.getters.accountGetToken;

	else if (typeof config.headers['AUTH-TOKEN'] != 'undefined')
		delete config.headers['AUTH-TOKEN'];

	return config;

}, (error: any) => {
	// Do something with request error
	return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response: any) {
	// Do something with response data
	let returnable = response.data;
	utils.mixedMSG(returnable.status, returnable.msg, returnable.class);
	return returnable;
}, function (error: any) {
	// Do something with response error
	return Promise.reject(error);
});

const makeImage = (img: [string, number, null], cfg: [string, undefined]) => {
	if(img){
		let imgURI = serverURI + 'img/render/' + img;
		if(cfg){
			imgURI += '/' + cfg + '/img.jpg';
		}
		return imgURI;
	} else {
		return 'https://via.placeholder.com/256';
	}
}

export { serverURI, makeImage};
