import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/MSISDN-View.vue'),
  },
  {
    path: '/captura-manual',
    name: 'ManualMSISDN',
    component: () => import('../views/ManualMSISDN.vue')
  },
  {
    path: '/acerca-de',
    name: 'acerca-de',
    component: () => import(/* webpackChunkName: "acerca-de" */ '../views/AboutView.vue'),
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "acerca-de" */ '../views/ContactView.vue'),
  },


  {
    path: '/content/:slug',
    name: 'Content',
    component: () => import(/* webpackChunkName: "acerca-de" */ '../views/Content.vue'),
  },

  {
    path: '/aviso-de-privacidad',
    name: 'aviso-de-privacidad',
    component: PrivacyPolicyView
  },
];

const router = new VueRouter({
  mode: 'history',  // Enable history mode
  routes,
});

export default router;
