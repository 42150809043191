//@ts-check


const plugin = (Vue) => {
  // SHARED
  Vue.component('herosViewer', () => import('./components/heros-viewer.vue'))
  Vue.component('contentViewer', () => import('./components/content-viewer.vue'))
  Vue.component('linksViewer', () => import('./components/links-viewer.vue'))
  Vue.component('uiInput', () => import('./components/ui-input.vue'))
  Vue.component('uiSelect', () => import('./components/ui-select.vue'))
  Vue.component('uiCountryPhoneInput', () => import('./components/ui-country-phone-input.vue'))
  Vue.component('uiSpinner', () => import('./components/spinner.vue'))
}

export default plugin;
