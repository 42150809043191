type Translation = {
    [key: string]: string;
};

export const translation: Translation = {
    
    // NAVIGATION
    'Inicio': 'Home',
    'Nuestro menú': 'Our menu',
    'Procesando': 'Processing',
    'Nombre' : 'Full name',
    'Correo electrónico' : 'E-mail',
    'Teléfono' : 'Phone',
    'Mensaje' : 'Message',
    'Mas' : 'More',
    'Comprimir' : 'Compress',
    'opciones' : 'options',

    // SERVICE NAMES
    'servicio_de_chefs_title': 'Chefs from Pre-set Menu',
    'servicio_de_chefs_desc': 'This mode allows you to have a chef with a menu of your choice for the people you choose immediately.',
    'servicio_de_chefs_bold': 'We take care of everything!',
    'chefs_dias_servicio_title': 'Chefs by Service Day',
    'chefs_dias_servicio_desc': 'This service is perfect for those looking for a personalized solution for events or extended stays.',
    'chefs_ondemand_title': 'Chefs on demand',
    'chefs_ondemand_desc': 'Hire our Chefs when you require them for the days you need.',
    'chefs_dias_servicio_note': '* This is the type of service that best suits villa owners or guest cabin owners.',

    // LANDING
    'landing_title': 'Welcome to Blackfon',
    'landing_subtitle': 'Chefs at your service',
    'landing_desc': 'With Blackfon, your culinary dreams come to life! Don\'t wait any longer and let us surprise you with our passion for gastronomy.',
    'landing_cta': 'Discover our menu',
    'our_services_desc': 'Our team of chefs will be happy to meet your culinary needs and provide you with an exceptional gastronomic experience.',

    // CONTACT
    'Formulario de contacto': "Contact form",
    'contact_lead': 'Please, fill out the following form to get in touch with us',
    'Ingrese su nombre': 'Enter your name',
    'Ingrese su correo electrónico': 'Enter your email',
    'Ingrese su número de teléfono': 'Enter your phone number',
    'Escriba su mensaje aquí': 'Write your message here',
    'Nombre completo': 'Full name',

    // DOMICILIO DETAILS FORM
    'Domicilio': 'Address',
    'Calle': 'Street',
    'Número exterior': 'Ext. number',
    'Número interior': 'Int. number',
    'Estado': 'State',
    'Municipio': 'municipality',
    'Colonia': 'Colony',
    'Código postal': 'Zip',
    'Referencias de la ubicación (opcional)': 'Location references (optional)',
    'Hotel, villa o condominio de referencia': 'Hotel, villa or condo of reference.',
    'P. e. Frente al parque.': 'e.g. In front of the park.',
    'Datos del cliente': 'Client´s data',
    'Teléfono celular': 'Phone number',
    'Fecha y hora': 'Schedule',
    'Fecha': 'Date',
    'Hora': 'Time',
    'Hora (Hora de servicio)': 'Time (Serving time)',
    'Comentarios del pedido': 'Order comments',
    'P. e. No nos gusta el picante, alergias, etc...': 'P. e. Not spicy meals, allergies, etc...',
    'zona': 'Zone',

    // MENU NAMES
    'main_dishes': 'Main dishes',
    'side_dishes': 'Side dishes',
    'menu_descripcion': 'Menu description',
    'not_menus_found': 'No menus found!',
    'our_menu': 'Our menu',
    'not_menus_found_lead': 'We\'re sorry, but we couldn\'t find any menus. Please try again later.',
    'not_content_found': 'Content not found',
    'not_content_found_lead': 'Please check later...',

    // HELPERS
    'Mas información': 'More information',
    'Enviar': 'Send',
    'Imprimir': 'Print',
    'stripePagosId': 'Stripe payment ID',
    'call_to_action': 'Book now!',

    // FOOTER
    'Nuestros servicios': 'Our services',
    'Todos los derechos reservados.': 'All rights reserved.',

    // MINOR DETAILS
    'nombres': 'Full name',
    'telefono_celular': 'Phone',
    'created': 'Creation date',
    'modified': 'Latest update',
    'menu_information_incomplete': 'Menu information incomplete',

    // PEDIDO
    'folio': 'Invoice',
    'estatus': 'Status',
    'agenda_inicio': 'Schedule',
    'agenda_horaservicio': 'Serving time. (The Chefs´ll arrive 1 hour early)',
    'pedidoTipo': 'Order type',
    'personas': 'Persons',
    'origenInsumos': 'Origin of inputs',
    'FullAddress': 'Address',
    'total': 'Total',
    'email': 'E-mail',
    'full_phone': 'Teléfono completo',
    'comentarios_cliente': 'Client´s comments'
};
